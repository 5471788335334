<template>
  <!-- eslint-disable -->
  <b-col md="8">
    <b-card class="text-center" footer-class="text-danger">
      <b-card-text class="mx-md-5">
        <label class="card-label-custom" for="feature-one-time-postback">Postback Integration Support (One-Time)</label>
        <hr><br class="feature-br">
        We will assist you in setting up a one-time server-to-server postback integration with your partner, advertiser,
        or affiliate platform.<br>Price: 150 EUR (one-time fee).
        <br><br>
        <b-button variant="primary" :disabled="sending.oneTimePostback" @click="purchaseOneTimePostback">
          <span v-if="sending.oneTimePostback">
            <b-spinner small type="grow" />
            Processing...
          </span>
          <span v-else-if="oneTimePostbackSessions > 0" class="d-flex align-items-center">
            <feather-icon icon="CalendarIcon" size="18" style="margin-right: 5px;" /> Schedule
          </span>
          <span v-else class="d-flex align-items-center">
            <feather-icon icon="ShoppingCartIcon" size="18" style="margin-right: 5px;" /> Purchase
          </span>
        </b-button><br>
        <div class="alert alert-primary mx-auto"
          style="width: fit-content; margin-top: 5px; margin-bottom: 0px; padding: 3px 5px;">
          {{ oneTimePostbackSessions }} session(s) available
        </div>
      </b-card-text>
    </b-card>
  </b-col>
</template>

<script>
/* eslint-disable */
import {
  BCard, BCol, BCardText, BButton, BSpinner,
} from 'bootstrap-vue'
import axios from '@/libs/axios'

export default {
  name: 'OneTimePostbackIntegration',
  components: {
    BCard, BCol, BCardText, BButton, BSpinner,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      sending: {
        oneTimePostback: false,
      },
    }
  },
  computed: {
    oneTimePostbackSessions() {
      return this.user?.ed?.oneTimePostbackSessions || 0
    }
  },
  mounted() {},
  methods: {
    purchaseOneTimePostback() {
      if (this.oneTimePostbackSessions > 0) {
        this.confirmationAlert('You will be redirected to the calendar page to schedule a postback integration session. Do you want to proceed?')
          .then(result => {
            if (result.isConfirmed) {
              const key = `${this.user.id}${this.user.uid}${new Date().getTime()}`
              const encodeKey = Buffer.from(key).toString('base64')
              const encodeID = Buffer.from(this.user.id.toString()).toString('base64')
              const encodeUID = Buffer.from(this.user.uid.toString()).toString('base64')
              const payload = {
                id: this.user.id,
                uid: this.user.uid,
                source: 'calendly-postback-integration',
                key: encodeKey,
              }
              axios.post('api/postback/calendly/add_key', payload)

              const URL = `https://calendly.com/francesco-tm/trafficmanager-postback-integration?utm_source=${encodeID}|${encodeUID}|${encodeKey}`
              window.open(URL, '_self')
            }
          })
      } else {
        this.confirmationAlert('You will be redirected and an invoice will be created, after paying it you can return here to schedule a postback integration session. ')
          .then(async result => {
            if (result.isConfirmed) {
              const res = await axios.post('api/optional_feature/schedule_session', { feature_id: 14 })
              if (res.status === 200) {
                if (res.data.denied) {
                  this.$bvToast.toast(res.data.message, {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                  })
                  return
                }
                localStorage.setItem('redirectToSession', true)
                localStorage.setItem('invoice_message', res.data.message)
                this.$router.push(`/invoice/current/${res.data.invoice_id}`)
              } else {
                this.$bvToast.toast('Something went wrong, please try again later!', {
                  title: 'Error',
                  variant: 'danger',
                  solid: true,
                })
              }
            }
          })
      }
    },
  },
}
</script>
