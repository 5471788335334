<template>
  <b-col
    md="8"
  >
    <b-card
      header=""
      :footer="item.footer"
      class="text-center"
      footer-class="text-danger"
    >
      <b-card-text class="mx-md-5">
        <label
          for="feature-1"
          class="card-label-custom"
        >VAST Pre-Roll (In-Video) AD feature</label>
        <hr>
        <br class="feature-br">
        This feature will open the VAST Pre-Roll (In-Video) promo tool for all your Marketplace offers. You will be able to upload short
        videos in each offer that will be served on the affiliate's websites which use this promo tool, typically just before the content video.
        <br><br>
        <b-form-select
          v-model="selected"
          :options="item.select_list"
          style="width:240px"
          :disabled="disableButton"
        />
        <br><br>
        <h5>{{ selectText() }}</h5>
        <br>
        <b-button
          v-if="disableButton"
          variant="danger"
          :disabled="sending"
          @click="selectItem()"
        >
          <span v-if="sending">
            <b-spinner
              small
              type="grow"
            />
            disabling...
          </span>
          <span
            v-else
            class="d-flex align-items-center"
          >
            <feather-icon
              icon="StopCircleIcon"
              size="18"
              style="margin-right: 5px;"
            /> Disable
          </span>
        </b-button>
        <b-button
          v-else-if="enableButton && isGoToInvoice"
          variant="primary"
          class="d-flex align-items-center m-auto"
          @click="goToInvoice()"
        >
          <feather-icon
            icon="PlayCircleIcon"
            size="18"
            style="margin-right: 5px;"
          /> Enable
        </b-button>
        <b-button
          v-else
          variant="primary"
          :disabled="sending"
          @click="selectItem()"
        >
          <span v-if="sending">
            <b-spinner
              small
              type="grow"
            />
            enabling...
          </span>
          <span
            v-else
            class="d-flex align-items-center"
          >
            <feather-icon
              icon="PlayCircleIcon"
              size="18"
              style="margin-right: 5px;"
            /> Enable
          </span>
        </b-button>
      </b-card-text>
    </b-card>
  </b-col>

</template>

<script>
import {
  BCard, BCol, BCardText, BButton, BSpinner, BFormSelect,
} from 'bootstrap-vue'

export default {
  components: {
    BCard, BCol, BCardText, BButton, BSpinner, BFormSelect,
  },
  props: {
    customItem: {
      type: Array,
      default: null,
    },
    enabled: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      item: {
        footer: '',
        select_list: [
          { value: 0, text: 'Please select an option' },
          { value: 8, text: '249 Euro/month for 1Gbit/s' },
          { value: 9, text: '399 Euro/month for 2Gbit/s' },
          { value: 10, text: '549 Euro/month for 3Gbit/s' },
          { value: 11, text: '749 Euro/month for 5Gbit/s' },
        ],
        select_list_complete: [
          { value: 0, text: 'No item selected.' },
          { value: 8, text: '1Gbit/s unlimited and guaranteed bandwidth + 512 GB SSD storage' },
          { value: 9, text: '2Gbit/s unlimited and guaranteed bandwidth + 512 GB SSD storage' },
          { value: 10, text: '3Gbit/s unlimited and guaranteed bandwidth + 1TB SSD storage' },
          { value: 11, text: '5Gbit/s unlimited and guaranteed bandwidth + 1TB SSD storage' },
        ],
      },
      enableButton: false,
      disableButton: false,
      sending: false,
      isGoToInvoice: false,
      selected: 8,
    }
  },
  computed: {
    user() {
      return this.$store.state.auth.userData
    },
  },
  mounted() {
    this.initVastPreRoll()
  },
  methods: {
    goToInvoice(id) {
      this.$router.push(`/invoice/current/${id}`)
    },
    initVastPreRoll() {
      this.enabled.forEach(item => {
        if (item.id === 8 || item.id === 9 || item.id === 10 || item.id === 11) {
          this.disableButton = true
          this.selected = item.id
        }
      })
    },
    selectItem() {
      let actionItem = null
      if (this.selected) {
        for (const item of this.customItem) { // eslint-disable-line
          if (item.id === this.selected) {
            actionItem = item
          }
        }
        this.toggleEnableDisabled(actionItem)
      } else {
        this.notify({
          text: 'Please select an item.',
          variant: 'danger',
        })
      }
    },
    selectText() {
      let textSelected = 'No item selected.'
      for (const item of this.item.select_list_complete) { // eslint-disable-line
        if (item.value === this.selected) textSelected = item.text
      }
      return textSelected
    },
    async toggleEnableDisabled(item) {
      if (this.user.user_status < 3) {
        this.notify({
          text: 'Cannot add Optional Features before entering trial period or paying for the subscription!',
          variant: 'danger',
        })
        return
      }
      if (this.user.user_status === 6 || this.user.user_status === 7) {
        this.notify({
          text: 'Cannot add Optional Features to a cancelled or expired account. Please contact us.',
          variant: 'danger',
        })
      } else {
        this.sending = true
        const payload = {
          feature_id: item.id,
        }
        const res = await this.$store.dispatch('optional/toggleOptionalFeatures', payload)
        this.sending = false
        if (res.isRedirectToInvoice) {
          const msg = res.message
          localStorage.setItem('invoice_message', msg)
          this.goToInvoice(item.invoice_id)
        } else {
          this.$router.go(0)
        }
      }
    },
  },

}
</script>

<style lang="scss" scoped>
.card-label-custom{
  font-size: 18px; color: #666
}
.dark-layout .card-label-custom{
  color: #f4f4f4
}
</style>
