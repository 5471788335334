<!-- eslint-disable  -->
<template>
  <div>
    <b-row class="d-flex justify-content-center">
      <!-- Schedule Calendar -->
      <b-col v-if="userSessions != null" md="8">
        <b-card class="text-center">
          <b-card-text class="mx-md-5">
            <label for="feature-1" class="card-label-custom">One-to-one training/consultancy session</label>
            <hr><br class="feature-br">You will receive a 45 minute one-to one training or business consultancy session
            from one of our highly experienced
            representatives which will teach you how to make better use of the TrafficManager platform for your business
            model.<br>Price: 200 EUR/session.
            <br><br>
            <b-button variant="primary" @click="requestScheduleSession">
              <span v-if="userSessions > 0" class="d-flex align-items-center">
                <feather-icon icon="CalendarIcon" size="18" style="margin-right: 5px;" /> Schedule
              </span>
              <span v-else class="d-flex align-items-center">
                <feather-icon icon="ShoppingCartIcon" size="18" style="margin-right: 5px;" />
                Buy Session
              </span>
            </b-button><br>
            <div class="alert alert-primary mx-auto"
              style="width: fit-content; margin-top: 5px; margin-bottom: 0px; padding: 3px 5px;">
              {{ userSessions }} free session(s)
            </div>
          </b-card-text>
          <div v-if="sessionFooter && userSessions == 0" class="text-warning">
            <hr>
            <div style="letter-spacing: 0.5px;">
              It looks like you've already used your one free training/consultancy session included in your
              subscription!
              Please note that all additional sessions will be charged. If you'd like to continue enjoying a free
              session every month,
              you might want to consider upgrading to our Agency or above package. Just a friendly suggestion!
            </div>
            <a class="text-success font-weight-bold" href="/account">Upgrade Plan</a>
          </div>
        </b-card>
      </b-col>

      <!-- Casino User -->
      <b-col v-if="user.package_id == 5003 || user.package_id == 6003" md="8">
        <b-card class="text-center" footer-class="text-danger">
          <b-card-text class="mx-md-5">
            <label class="card-label-custom" for="feature-4">Operator Integration Support via Postback</label>
            <hr><br class="feature-br">
            We will take care of all the aspects of the integrations with your partners/operators with TrafficManager
            via server-to-server Postback ( up to 5 integrations/month).<br>Price: +250 EUR/month, 500 EUR/first month.
            <br><br>
            <b-button v-if="item13.disableButton" variant="danger" :disabled="sending[item13.id]"
              @click="toggleEnableDisabled(item13)">
              <span v-if="sending[item13.id]">
                <b-spinner small type="grow" />
                disabling...
              </span>
              <span v-else class="d-flex align-items-center">
                <feather-icon icon="StopCircleIcon" size="18" style="margin-right: 5px;" /> Disable
              </span>
            </b-button>
            <b-button v-else-if="item13.enableButton && item13.goToInvoice" variant="primary"
              class="d-flex align-items-center m-auto" @click="goToInvoice(item13.invoice_id)">
              <feather-icon icon="PlayCircleIcon" size="18" style="margin-right: 5px;" /> Enable
            </b-button>
            <b-button v-else variant="primary" :disabled="sending[item13.id]" @click="toggleEnableDisabled(item13)">
              <span v-if="sending[item13.id]">
                <b-spinner small type="grow" />
                enabling...
              </span>
              <span v-else class="d-flex align-items-center">
                <feather-icon icon="PlayCircleIcon" size="18" style="margin-right: 5px;" /> Enable
              </span>
            </b-button>
          </b-card-text>
        </b-card>
      </b-col>

      <!-- Normal User -->
      <b-col v-else md="8">
        <b-card class="text-center" footer-class="text-danger">
          <b-card-text class="mx-md-5">
            <label class="card-label-custom" for="feature-4">Postback Integration Support (Recurring)</label>
            <hr><br class="feature-br">
            We will assist you in integrating via server-to-server postback with your partners, advertiser or
            affiliates, by setting up postback URLs in TrafficManager or in their platform (5
            integrations/month).<br>Price: +250 EUR/month, 500 EUR/first month.
            <br><br>
            <b-button v-if="item4.disableButton" variant="danger" :disabled="sending[item4.id]"
              @click="toggleEnableDisabled(item4)">
              <span v-if="sending[item4.id]">
                <b-spinner small type="grow" />
                disabling...
              </span>
              <span v-else class="d-flex align-items-center">
                <feather-icon icon="StopCircleIcon" size="18" style="margin-right: 5px;" /> Disable
              </span>
            </b-button>
            <b-button v-else-if="item4.enableButton && item4.goToInvoice" variant="primary"
              class="d-flex align-items-center m-auto" @click="goToInvoice(item4.invoice_id)">
              <feather-icon icon="PlayCircleIcon" size="18" style="margin-right: 5px;" /> Enable
            </b-button>
            <b-button v-else variant="primary" :disabled="sending[item4.id]" @click="toggleEnableDisabled(item4)">
              <span v-if="sending[item4.id]">
                <b-spinner small type="grow" />
                enabling...
              </span>
              <span v-else class="d-flex align-items-center">
                <feather-icon icon="PlayCircleIcon" size="18" style="margin-right: 5px;" /> Enable
              </span>
            </b-button>
            <b-button style="margin-left: 15px;" v-if="recurringPostbackSessions > 0" variant="primary" @click="requestPostbackSession">
              <span class="d-flex align-items-center">
                <feather-icon icon="CalendarIcon" size="18" style="margin-right: 5px;" /> Schedule
              </span>
            </b-button>
            <br>
            <div class="alert alert-primary mx-auto"
              style="width: fit-content; margin-top: 5px; margin-bottom: 0px; padding: 3px 5px;">
              {{ recurringPostbackSessions }} session(s) available
            </div>
          </b-card-text>
        </b-card>
      </b-col>

      <one-time-postback-integration :user="user" />

      <!-- ---------- -->
      <vast-pre-roll-ad-vue v-if="cutomItem" :custom-item="cutomItem" :enabled="enabled" />

      <b-col md="8">
        <b-card v-for="item in list" :key="item.id" header="" :footer="item.footer" class="text-center"
          footer-class="text-danger">
          <b-card-text class="mx-md-5">
            <div v-html="item.description" />
            <br><br>
            <b-button v-if="item.disableButton" variant="danger" :disabled="sending[item.id]"
              @click="toggleEnableDisabled(item)">
              <span v-if="sending[item.id]">
                <b-spinner small type="grow" />
                disabling...
              </span>
              <span v-else class="d-flex align-items-center">
                <feather-icon icon="StopCircleIcon" size="18" style="margin-right: 5px;" /> Disable
              </span>
            </b-button>
            <b-button v-else-if="item.enableButton && item.goToInvoice" variant="primary"
              class="d-flex align-items-center m-auto" @click="goToInvoice(item.invoice_id)">
              <feather-icon icon="PlayCircleIcon" size="18" style="margin-right: 5px;" /> Enable
            </b-button>
            <b-button v-else variant="primary" :disabled="sending[item.id]" @click="toggleEnableDisabled(item)">
              <span v-if="sending[item.id]">
                <b-spinner small type="grow" />
                enabling...
              </span>
              <span v-else class="d-flex align-items-center">
                <feather-icon icon="PlayCircleIcon" size="18" style="margin-right: 5px;" /> Enable
              </span>
            </b-button>

          </b-card-text>

        </b-card>
      </b-col>

    </b-row>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BCardText, BButton, BSpinner,
} from 'bootstrap-vue'

import axios from '@/libs/axios'
import VastPreRollAdVue from './optional-features/VastPreRollAd.vue'
import OneTimePostbackIntegration from './optional-features/OneTimePostbackIntegration.vue'

export default {
  components: {
    BCard, BRow, BCol, BCardText, BButton, BSpinner, VastPreRollAdVue, OneTimePostbackIntegration,
  },
  data() {
    return {
      isDynamicTimeZoneStatVisible: true,
      enabled: [],
      optional_features: [],
      status_trial: 0,
      user_status: 0,
      trial_allowed: [],
      list: [],
      sending: {
        1: false, 2: false, 3: false, 4: false, 5: false, 6: false, 7: false,
      },
      cutomItem: null,
      userSessions: null,
      packageSessions: null,
      sessionFooter: null,
      item4: {
        disableButton: false,
      },
      item13: {
        disableButton: false,
      },
    }
  },
  computed: {
    user() {
      return this.$store.state.auth.userData
    },

    featureData() {
      return this.$store.state.optional.featureData
    },

    recurringPostbackSessions() {
      return this.user?.ed?.recurringPostbackSessions || 0
    },
  },
  created() {
    this.fetchOptionalFeatures()
  },
  mounted() {
    this.fetchUserSessions()
  },
  methods: {
    requestPostbackSession() {
      if (this.recurringPostbackSessions > 0) {
        this.confirmationAlert('You will be redirected to the calendar page to schedule a postback integration session. Do you want to proceed?')
          .then(result => {
            if (result.isConfirmed) {
              const key = `${this.user.id}${this.user.uid}${new Date().getTime()}`
              const encodeKey = Buffer.from(key).toString('base64')
              const encodeID = Buffer.from(this.user.id.toString()).toString('base64')
              const encodeUID = Buffer.from(this.user.uid.toString()).toString('base64')
              const payload = {
                id: this.user.id,
                uid: this.user.uid,
                source: 'calendly-postback-integration',
                key: encodeKey,
              }
              axios.post('api/postback/calendly/add_key', payload)

              const URL = `https://calendly.com/francesco-tm/trafficmanager-postback-integration?utm_source=${encodeID}|${encodeUID}|${encodeKey}`
              window.open(URL, '_self')
            }
          })
      }
    },
    requestScheduleSession() {
      if (this.userSessions != null && this.packageSessions != null) {
        if (this.userSessions > 0) {
          this.confirmationAlert('You will be redirected to the calendar page to schedule a training session. Do you want to continue?')
            .then(result => {
              if (result.isConfirmed) {
                const key = `${this.user.id}${this.user.uid}${new Date().getTime()}`
                const encodeKey = Buffer.from(key).toString('base64')
                const encodeID = Buffer.from(this.user.id.toString()).toString('base64')
                const encodeUID = Buffer.from(this.user.uid.toString()).toString('base64')
                const payload = {
                  id: this.user.id,
                  uid: this.user.uid,
                  source: 'calendly',
                  key: encodeKey,
                }
                axios.post('api/postback/calendly/add_key', payload)

                const URL = `https://calendly.com/trafficmanager/training?utm_source=${encodeID}|${encodeUID}|${encodeKey}`
                window.open(URL, '_self')
              }
            })
        } else {
          this.confirmationAlert('You will be redirected and an invoice will be created, after paying it you can return here to schedule a session. ')
            .then(async result => {
              if (result.isConfirmed) {
                const res = await axios.post('api/optional_feature/schedule_session', { feature_id: 12 })
                if (res.status === 200) {
                  if (res.data.denied) {
                    this.$bvToast.toast(res.data.message, {
                      title: 'Error',
                      variant: 'danger',
                      solid: true,
                    })
                    return
                  }
                  localStorage.setItem('redirectToSession', true)
                  localStorage.setItem('invoice_message', res.data.message)
                  this.$router.push(`/invoice/current/${res.data.invoice_id}`)
                } else {
                  this.$bvToast.toast('Something went wrong, please try again later!', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                  })
                }
              }
            })
        }
      }
    },
    goToInvoice(id) {
      this.$router.push(`/invoice/current/${id}`)
    },
    async toggleEnableDisabled(item) {
      if (this.user.user_status < 4) {
        this.notify({
          text: 'Cannot add Optional Features before entering trial period !',
          variant: 'danger',
        })
        return
      }
      if (this.user.user_status === 6 || this.user.user_status === 7) {
        this.notify({
          text: 'Cannot add Optional Features to a cancelled or expired account. Please contact us.',
          variant: 'danger',
        })
      } else {
        this.sending[item.id] = true
        const payload = {
          feature_id: item.id,
        }
        if ((item.id === 5 || item.id === 6) && this.user.user_status !== 8) {
          this.activateBackup(item.id)
          this.sending[item.id] = false
          return
        }

        const res = await this.$store.dispatch('optional/toggleOptionalFeatures', payload)
        this.sending[item.id] = false
        if (res.isRedirectToInvoice) {
          const msg = res.message
          localStorage.setItem('invoice_message', msg)
          this.goToInvoice(item.invoice_id)
        } else {
          this.$router.go(0)
        }
        // Execute the relaad after 4 seconds
        setTimeout(() => {
          this.$router.go(0)
        }, 4000)
      }
    },
    async activateBackup(feature_id) {
      const payload = {
        feature_id,
      }
      if (this.user.user_status !== 8) {
        this.confirmationAlert('To activate the backup, a pro forma invoice will be generated, and after the payment, the backup will be activated. Do you want to continue?')
          .then(async result => {
            if (result.isConfirmed) {
              const res = await axios.post('api/optional_feature/activate_backup', payload)
              if (res.status === 200) {
                if (res.data.denied) {
                  this.$bvToast.toast(res.data.message, {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                  })
                  return
                }
                localStorage.setItem('invoice_message', res.data.message)
                this.$router.push(`/invoice/current/${res.data.invoice_id}`)
              } else {
                this.$bvToast.toast('Something went wrong, please try again later!', {
                  title: 'Error',
                  variant: 'danger',
                  solid: true,
                })
              }
            }
          })
      }
    },
    fetchUserSessions() {
      // fetch user & package sessions
      axios.get(`api/users/user_sessions/${this.user.id}`).then(result => {
        if (result && result?.data) {
          this.userSessions = result.data.sessions
          this.packageSessions = result.data.packageSessions
          if (result.data.packageName === 'STARTER' || result.data.packageName === 'PROFESSIONAL') this.sessionFooter = 1
        }
      }).catch(() => {
        this.userSessions = null
        this.packageSessions = null
      })
    },
    async fetchOptionalFeatures() {
      await this.$store.dispatch('optional/fetchOptionalFeatures')

      this.enabled = this.featureData.enabled
      this.optional_features = this.featureData.optional_features
      this.status_trial = this.featureData.status_trial
      this.user_status = this.featureData.user_status
      this.trial_allowed = this.featureData.trial_allowed
      this.cutomItem = this.featureData.custom_optional_features

      // Temp: hardcoded user not allowed to see dynamic time zone statistic
      if (this.user.uid === 3859612981 || this.user.uid === 1339413587) {
        this.isDynamicTimeZoneStatVisible = false
      }

      this.optional_features.forEach(feature => {
        if (this.user_status >= this.status_trial || this.trial_allowed.includes(feature.id)) {
          const obj = feature
          const id = Number(feature.id)

          obj.footer = ''
          if (id === 1) { obj.footer = 'If you disable the option, all the existing data will be permanently deleted.' }

          obj.disableButton = false
          obj.enableButton = true
          if (feature.hasOptionalFeature) {
            obj.disableButton = true
            obj.enableButton = false
          }

          obj.goToInvoice = false
          if (feature.isPfi) {
            obj.goToInvoice = true
            obj.invoice_id = feature.invoice_id
          }

          if (id !== 4) {
            if (feature.id === 7 && this.isDynamicTimeZoneStatVisible === true) this.list.push(obj)
            else if (feature.id < 7) this.list.push(obj)
          } else this.item4 = obj

          if (id === 13) {
            this.item13 = obj
          }
        }
      })
    },
  },

}
</script>

<style lang="scss">
.card-label-custom {
  font-size: 18px;
  color: #666;
}

.dark-layout label {
  color: #f4f4f4 !important;
}
</style>
